:root {
    --APP_BACKGROUND: #2A3258;
    --NAV_BAR_BACKGROUND: #3C4888;
    --NAV_BAR_AVATAR: #acb4e0;
    --LEFT_PANEL_BACKGROUND: #303964;
    --ALERT_TABLE_BACKGROUND: #3b4473;
    --LEFT_PANEL_BACKGROUND_HOVER: #5767b8;
    --LEFT_PANEL_BUTTON_BACKGROUND: #3c4888;
    --ALERT_DETAIL_SINGLE_BACKGROUND: #3b477a;
    --TABLE_ROW_LIGHT: #3c4473;
    --TABLE_ROW_DARK: #2d3456;
    --TREND_GRAPH: #2d3456;
    --TREND_GRAPH_BACKGROUND:#2d3456;
    --ACTIVE: #fcc84a;
    --APPLY_BUTTON: #3fb3df;
    --APPLY_BUTTON_TEXT: #0A0A0A;
    --GRAPH_BACKGROUND: #131731;
    --CRITICAL: #b73e3e;
    --MAJOR: #b73e3e;
    --MINOR: #1890ff;
    --MEDIUM: #ebda05;
    --LEFT_PANEL_WIDTH: 96px;
    --TOP_HEADER_HEIGHT: 40px;
    --TABLE_BACKGROUND: #3c4473;
    --DROPDOWN_BACKGROUND_COLOR: #383e5f;
    --APPLY_BUTTON: #3fb3df;
    --ROW_HOVER: #8699ff;
    --MIS_BACKGROUND: #2b3254;
    --MIS_CONTAINER_BACKGROUND: #363e69;
    --MIS_ANALYSER_BUTTON: #4d5fc1;
    --MIS_CARD_BACKGROUND: #00000033;
    --ADDITIONAL_INSIGHTS_BACKGROUND: #3b477a;
    --PAST_ALERT_BACKGROUND_HEADER: #3b477a;
    --MODAL_BACKGROUND: #2A3258;
    --MODAL_BODY_BACKGROUND: #2A3258;
    --DYNAMIC_TABLE_BACKGROUND: #fff;
    --DYANMIC_TABLE_ROW_LIGHT: #e5e5e5;
    --DYANMIC_TABLE_ROW_DARK: rgba(0, 0, 0, 0.05);
    --DYNAMIC_TABLE_TEXT_COLOR: black;
    --DYNAMIC_TABLE_HEADER_BACKGROUND: #fff;
    --DYNAMIC_TABLE_HEADER_BACKFROUND: #fff;
    --RADIO_BUTTON_COLOR: #3d487d;
    --RADIO_BUTTON_HOVER: #5767b8;
    --RADIO_BUTTON_CLICKED: #5767b8;
    --DYNAMIC_DROPDOWN_BACKGROUND: #383e5f;
    --DYNAMIC_SELECT_DROPDOWN: #3d487d;
    --DYNAMIC_SELECT_DROPDOWN_TEXT: #a5b8ff;
    --DYNAMIC_TABLE_PAGINATION_BACKGROUND: #303964;
    --B2B_CARD: #3d487d;
    --B2B_CARD_TEXT: #a5b8ff;
    --DYANMIC_TABLE_ROW_SELECTION: #f3ffe6;
    --CLEAR_ALERT_BUTTON: #3fb3df;
    --COLLAPSE: #6d7cc2;
    --COLLAPSE_COLOR: #3f4b81;
    --ACTIVE_TAB: #fcc84a;
    --DATE_PICKER_BACKGROUND: #3d487d;
    --DATE_PICKER_TEXT: #a5b8ff;
    --DATE_PICKER_CONTENT_HEADER: #c28e2b;
    --DATE_PICKER_FOOTER_TEXT: #1890ff;
    --CUSTOM_DASHBOARD_CARD: #3D4980;
    --B2B_DROPDOWN_SELECT_ARROW: #a5b8ff;
    --HOME_CONTENT_SCREEN_WIDTH: calc(100vw - 40px);
    --HOME_LEFT_CONTENT_WIDTH: 75%;
    --HOME_RIGHT_CONTENT_WIDTH: 25%;
    --HOME_CONTENT_TO_LEFT: 50px;
    --LEFT_PANEL_BORDER_RADIUS: 0px;
    --LEFT_PANEL_ICON_WIDTH: 47px;
    --LEFT_PANEL_MARGIN_LEFT: 10px;
    --LEFT_PANEL_BUTTON_MARGIN: 0px 0px;
    --MIS_CARD_BODY_BACKGROUND: #3b477a;
    --MIS_CARD_TITLE_TEXT: #a5b8ff;
    --DYNAMIC_COMPONENT_WIDTH: calc(100vw - 50px);
    --DYNAMIC_COMPONENT_CONTENT_LEFT: "48px";
    --B2B_MAP_WIDTH: "100%";
    --B2B_RIGHT_INFO_WIDTH:"26vw";
    --ALERT_DETAIL_CONTAINER_WIDTH: "auto";
    --MIS_WIDTH: "calc(100vw - 45px)";
    --GREY_1: rgba(0, 0, 0, 0.07);
    --ACTIVE_LEFT_PANEL_TAB: #fcc84a;
    --ZOOM_BUTTON: 10;
    --ALERT_HEADER_WIDTH: 22%;
    --ALERT_HEADER_WIDTH_LEFT: 74%;
    --DYNAMIC_TABLE_HEADER_TEXT: #3f4b81; 
    --DYNAMIC_TABLE_ROW_BORDER: "1px solid #f0f0f0";
    --DYNAMIC_TABLE_ROW_HEADER_BORDER: ""1px solid #f0f0f0"";
    --DYNAMIC_TABLE_ROE_HEIGHT: "38px";
    --DYNAMIC_COMPONENT_MARGIN: "5px 0px 0px 0px";
    --DYNAMIC_COMPONENT_SUBHEADER_MARGIN: "0px";
    --MODULE_DROPDOWN_MARGIN: "5px 1px 0px 0px";
    --RADIO_BUTTON_WIDTH: "60px";
    --RADIO_BUTTON_DIVIDER_BACKGROUND_COLOR: "#1890ff";
    --RADIO_BUTTON_DIVIDER_HEIGHT: "0px";
    --RADIO_BUTTON_DIVIDER_TOP_MARGIN: "0px";
    --DYNAMIC_SELECT_TOP: "5px";
    --GO_BUTTON_MARGIN_LEFT: "0px";
    --BUTTON_HEIGHT: "30px";
    --DYNAMIC_SELECT_HEIGHT: "32px";
    --INPUT_TOP: "3px";
    --INPUT_WIDTH: "200px";
    --BUTTON_TOP: "0px";
    --INPUT_HEIGHT: "";
    --DYNAMIC_COMPONENT_MARGIN_TOP: "0px";
    --INPUT_BACKGROUND_COLOR: "rgba(0, 0, 0, 0.3)";
    --B2B_CONTAINER_PADDING: "0px";
    --B2B_HEADER_TOP: "1px";
    --B2B_INPUT_TOP:"10px";
    --B2B_MAP_HEIGHT: "80vh";
    --B2B_RADIO_BUTTON_WIDTH: "60px";
    --B2B_RADIO_BUTTON_HEIGHT: "100%";
    --B2B_TABLE_WIDTH: "100%";
    --LEFT_PANEL_TEXT_COLOR: "#fff";
    --DATE_FILTER_HEIGHT: "";
    --GRID_CONTAINER_BACKGROUND: "#07080b";
    --DYNAMIC_TABLE_HEIGHT: "calc(100vh - 140px)";
    --SCROLLBAR_COLOR: "#fcc84a";
    --B2B_TABLE_HEIGHT: "calc(100vh - 140px)";
    --B2B_TABLE_WIDTH_CUSTOME: "100%";
    --B2B_TABLE_PAGINATION: "#303964";
    --DYNAMIC_SELECT_BACKGROUND: "rgb(31, 31, 31)";
    --DYNAMIC_SELECT_TOP1: "0px";
    --DYNAMIC_SELECT_WIDTH: "20px"; 
    --DYNAMIC_SELECT_PADDING: "5px";
    --DYNAMIC_SEARCH_BACKGROUND_COLOR: "rgba(0, 0, 0, 0)";
    --LOADING_COLOR: "#ffae00"; 
    --REFRESH_BUTTON_HEIGHT: "40px";
    --WEBHEADER_MARGIN_LEFT: "0px";
    --DATE_PICKER_TOP: "10px";
    --ADD_BUTTON_TOP: "3px";
    --ADD_BUTTON_MARGIN_LEFT: "2px";
    --FILTER_BUTTON_TOP: "3px";
    --FILTER_BUTTON_RIGHT: "0px";
    --EDIT_BUTTON_MARGIN_LEFT: "1px";
    --FILTER_DYNAMIC_BUTTON_TOP: "3px";
    --APPLY_BUTTON_HEADER_COLOR: "#3fb3df";
    --APPLY_BUTTON_BORDER: "none";
    --TREND_DROPDOWN_ACTIVE: "1px solid #fcc84a";
    --DYNAMIC_TABLE_HEADER_FONT_FAMILY: "Roboto, sans-serif";
    --DYNAMIC_TABLE_BODY_FONT_FAMILY: "Roboto, sans-serif";
    --QUICKSIGHT_COMPONENT_MARGIN: "0px";
    --QUICKSIGHT_COMPONENT_WIDTH: "calc(100vw - 50px)";
    --QUICKSIGHT_COMPONENT_MIN_HEIGHT: "100vh";
    // --INPUT_MEDIA_QUERY_TOP: "5px";
    // --INPUT_MEDIA_QUERY_RIGHT: "0px";
    }
    
    $APP_BACKGROUND:var(--APP_BACKGROUND);
    $NAV_BAR_BACKGROUND:var(--NAV_BAR_BACKGROUND);
    $NAV_BAR_AVATAR:var(--NAV_BAR_AVATAR);
    $LEFT_PANEL_BACKGROUND:var(--LEFT_PANEL_BACKGROUND);
    $ALERT_TABLE_BACKGROUND:var(--ALERT_TABLE_BACKGROUND);;
    $LEFT_PANEL_BACKGROUND_HOVER:var(--LEFT_PANEL_BACKGROUND_HOVER);
    $LEFT_PANEL_BUTTON_BACKGROUND:var(--LEFT_PANEL_BUTTON_BACKGROUND);
    $LEFT_PANEL_BUTTON_HOVER_COLOR: var(--LEFT_PANEL_BUTTON_HOVER_COLOR);
    $ALERT_DETAIL_SINGLE_BACKGROUND:var(--ALERT_DETAIL_SINGLE_BACKGROUND);
    $TABLE_ROW_LIGHT:var(--TABLE_ROW_LIGHT);
    $TABLE_ROW_DARK:var(--TABLE_ROW_DARK);
    $TEXT_COLOR: var(--TEXT_COLOR);
    $TREND_GRAPH:var(--TREND_GRAPH);
    $TREND_GRAPH_BACKGROUND:var(--TREND_GRAPH_BACKGROUND);
    $ACTIVE:var(--ACTIVE);
    $APPLY_BUTTON:var(--APPLY_BUTTON);
    $APPLY_BUTTON_TEXT:var(--APPLY_BUTTON_TEXT);
    $RESET_BUTTON_TEXT: var(--RESET_BUTTON_TEXT);
    $GRAPH_HEADER_BACKGROUND: var(--GRAPH_HEADER_BACKGROUND);
    $GRAPH_BACKGROUND:var(--GRAPH_BACKGROUND);
    $CRITICAL:var(--CRITICAL);
    $MAJOR:var(--MAJOR);
    $MINOR:var(--MINOR);
    $MEDIUM:var(--MEDIUM);
    $LEFT_PANEL_WIDTH:var(--LEFT_PANEL_WIDTH);
    $TOP_HEADER_HEIGHT:var(--TOP_HEADER_HEIGHT);
    $TABLE_BACKGROUND:var(--TABLE_BACKGROUND);
    $DROPDOWN_BACKGROUND_COLOR:var(--DROPDOWN_BACKGROUND_COLOR);
    $APPLY_BUTTON:var(--APPLY_BUTTON);
    $ROW_HOVER:var(--ROW_HOVER);
    $MIS_BACKGROUND:var(--MIS_BACKGROUND);
    $MIS_CONTAINER_BACKGROUND:var(--MIS_CONTAINER_BACKGROUND);
    $MIS_ANALYSER_BUTTON:var(--MIS_ANALYSER_BUTTON);
    $LOGIN_FORM: var(--LOGIN_FORM);
    $MIS_CARD_BACKGROUND:var(--MIS_CARD_BACKGROUND);
    $ADDITIONAL_INSIGHTS_BACKGROUND:var(--ADDITIONAL_INSIGHTS_BACKGROUND);
    $PAST_ALERT_BACKGROUND_HEADER:var(--PAST_ALERT_BACKGROUND_HEADER);
    $MODAL_BACKGROUND:var(--MODAL_BACKGROUND);
    $MODAL_BODY_BACKGROUND:var(--MODAL_BODY_BACKGROUND);
    $MODAL_HEADER_BACKGROUND: var(--MODAL_HEADER_BACKGROUND);
    $MODAL_TEXT: var(--MODAL_TEXT);
    $DYNAMIC_TABLE_BACKGROUND:var(--DYNAMIC_TABLE_BACKGROUND);
    $DYANMIC_TABLE_ROW_LIGHT:var(--DYANMIC_TABLE_ROW_LIGHT);
    $DYANMIC_TABLE_ROW_DARK:var(--DYANMIC_TABLE_ROW_DARK);
    $DYNAMIC_TABLE_TEXT_COLOR:var(--DYNAMIC_TABLE_TEXT_COLOR);
    $DYNAMIC_TABLE_HEADER_BACKGROUND:var(--DYNAMIC_TABLE_HEADER_BACKGROUND);
    $RADIO_BUTTON_COLOR:var(--RADIO_BUTTON_COLOR);
    $RADIO_BUTTON_HOVER:var(--RADIO_BUTTON_HOVER);
    $RADIO_BUTTON_CLICKED:var(--RADIO_BUTTON_CLICKED);
    $DYNAMIC_DROPDOWN_BACKGROUND:var(--DYNAMIC_DROPDOWN_BACKGROUND);
    $DYNAMIC_SELECT_DROPDOWN:var(--DYNAMIC_SELECT_DROPDOWN);
    $DYNAMIC_SELECT_DROPDOWN_TEXT:var(--DYNAMIC_SELECT_DROPDOWN_TEXT);
    $DYNAMIC_TABLE_PAGINATION_BACKGROUND:var(--DYNAMIC_TABLE_PAGINATION_BACKGROUND);
    $DYNAMIC_TABLE_PAGINATION_ITEM_BACKGROUND:var(--DYNAMIC_TABLE_PAGINATION_ITEM_BACKGROUND);
    $B2B_CARD:var(--B2B_CARD);
    $B2B_CARD_TEXT:var(--B2B_CARD_TEXT);
    // $DYNAMIC_TABLE_PAGINATION_ITEM:var(--DYNAMIC_TABLE_PAGINATION_ITEM);
    $DYANMIC_TABLE_ROW_SELECTION:var(--DYANMIC_TABLE_ROW_SELECTION);
    $CLEAR_ALERT_BUTTON:var(--CLEAR_ALERT_BUTTON);
    $COLLAPSE:var(--COLLAPSE);
    $COLLAPSE_COLOR:var(--COLLAPSE_COLOR);
    $ACTIVE_TAB:var(--ACTIVE_TAB);
    $DATE_PICKER_BACKGROUND:var(--DATE_PICKER_BACKGROUND);
    $DATE_PICKER_TEXT:var(--DATE_PICKER_TEXT);
    $DATE_PICKER_CONTENT_HEADER:var(--DATE_PICKER_CONTENT_HEADER);
    $DATE_PICKER_FOOTER_TEXT:var(--DATE_PICKER_FOOTER_TEXT);
    $DATE_PICKER_PANEL_BACKGROUND:var(--DATE_PICKER_PANEL_BACKGROUND);
    $CUSTOM_DASHBOARD_CARD:var(--CUSTOM_DASHBOARD_CARD);
    $B2B_DROPDOWN_SELECT_ARROW:var(--B2B_DROPDOWN_SELECT_ARROW);
    $HOME_CONTENT_SCREEN_WIDTH: var(--HOME_CONTENT_SCREEN_WIDTH);
    $HOME_LEFT_CONTENT_WIDTH: var(--HOME_LEFT_CONTENT_WIDTH);
    $HOME_RIGHT_CONTENT_WIDTH: var(--HOME_RIGHT_CONTENT_WIDTH);
    $HOME_CONTENT_TO_LEFT: var(--HOME_CONTENT_TO_LEFT);
    $LEFT_PANEL_BORDER_RADIUS: var(--LEFT_PANEL_BORDER_RADIUS);
    $LEFT_PANEL_ICON_WIDTH: var(--LEFT_PANEL_ICON_WIDTH);
    $LEFT_PANEL_MARGIN_LEFT: var(--LEFT_PANEL_MARGIN_LEFT);
    $LEFT_PANEL_BUTTON_MARGIN: var(--LEFT_PANEL_BUTTON_MARGIN);
    $MIS_CARD_BODY_BACKGROUND: var(--MIS_CARD_BODY_BACKGROUND);
    $MIS_CARD_TITLE_TEXT: var(--MIS_CARD_TITLE_TEXT);
    $DYNAMIC_COMPONENT_WIDTH: var(--DYNAMIC_COMPONENT_WIDTH);
    $DYNAMIC_COMPONENT_CONTENT_LEFT: var(--DYNAMIC_COMPONENT_CONTENT_LEFT);
    $B2B_MAP_WIDTH: var(--B2B_MAP_WIDTH);
    $B2B_RIGHT_INFO_WIDTH: var(--B2B_RIGHT_INFO_WIDTH);
    $ALERT_DETAIL_CONTAINER_WIDTH: var(--ALERT_DETAIL_CONTAINER_WIDTH);
    $MIS_WIDTH: var(--MIS_WIDTH);
    $GREY_1:var(--GREY_1);
    $ACTIVE_LEFT_PANEL_TAB: var(--ACTIVE_LEFT_PANEL_TAB);
    $ZOOM_BUTTON: var(--ZOOM_BUTTON);
    $ALERT_HEADER_WIDTH: var(--ALERT_HEADER_WIDTH);
    $ALERT_HEADER_WIDTH_LEFT: var(--ALERT_HEADER_WIDTH_LEFT);
    $DYNAMIC_TABLE_HEADER_TEXT: var(--DYNAMIC_TABLE_HEADER_TEXT);
    $DYNAMIC_TABLE_ROW_BORDER: var(--DYNAMIC_TABLE_ROW_BORDER);
    $DYNAMIC_TABLE_ROW_HEADER_BORDER: var(--DYNAMIC_TABLE_ROW_HEADER_BORDER);
    $DYNAMIC_TABLE_ROW_HEIGHT: var(--DYNAMIC_TABLE_ROW_HEIGHT);
    $DYNAMIC_COMPONENT_MARGIN: var(--DYNAMIC_COMPONENT_MARGIN);
    $DYNAMIC_COMPONENT_SUBHEADER_MARGIN: var(--DYNAMIC_COMPONENT_SUBHEADER_MARGIN);
    $MODULE_DROPDOWN_MARGIN: var(--MODULE_DROPDOWN_MARGIN);
    $RADIO_BUTTON_WIDTH: var(--RADIO_BUTTON_WIDTH);
    $RADIO_BUTTON_DIVIDER_BACKGROUND_COLOR: var(--RADIO_BUTTON_DIVIDER_BACKGROUND_COLOR);
    $RADIO_BUTTON_DIVIDER_HEIGHT: var(--RADIO_BUTTON_DIVIDER_HEIGHT);
    $RADIO_BUTTON_DIVIDER_TOP_MARGIN: var(--RADIO_BUTTON_DIVIDER_TOP_MARGIN);
    $DYNAMIC_SELECT_TOP: var(--DYNAMIC_SELECT_TOP);
    $BUTTON_MARGIN_LEFT: var(--BUTTON_MARGIN_LEFT);
    $BUTTON_HEIGHT: var(--BUTTON_HEIGHT);
    $DYNAMIC_SELECT_HEIGHT: var(--DYNAMIC_SELECT_HEIGHT);
    $INPUT_TOP: var(--INPUT_TOP);
    $INPUT_WIDTH: var(--INPUT_WIDTH);
    $BUTTON_TOP: var(--BUTTON_TOP);
    $INPUT_HEIGHT: var(--INPUT_HEIGHT);
    $DYNAMIC_COMPONENT_MARGIN_TOP: var(--DYNAMIC_COMPONENT_MARGIN_TOP);
    $INPUT_BACKGROUND_COLOR: var(--INPUT_BACKGROUND_COLOR);
    $B2B_CONTAINER_PADDING: var(--B2B_CONTAINER_PADDING);
    $B2B_HEADER_TOP: var(--B2B_HEADER_TOP);
    $B2B_INPUT_TOP: var(--B2B_INPUT_TOP);
    $B2B_MAP_HEIGHT: var(--B2B_MAP_HEIGHT);
    $B2B_RADIO_BUTTON_WIDTH: var(--B2B_RADIO_BUTTON_WIDTH);
    $B2B_RADIO_BUTTON_HEIGHT: var(--B2B_RADIO_BUTTON_HEIGHT);
    $B2B_TABLE_WIDTH: var(--B2B_TABLE_WIDTH);
    $LEFT_PANEL_TEXT_COLOR: var(--LEFT_PANEL_TEXT_COLOR);
    $DATE_FILTER_HEIGHT: var(--DATE_FILTER_HEIGHT);
    $GRID_CONTAINER_BACKGROUND: var(--GRID_CONTAINER_BACKGROUND);
    $DYNAMIC_TABLE_HEIGHT: var(--DYNAMIC_TABLE_HEIGHT);
    $SCROLLBAR_COLOR: var(--SCROLLBAR_COLOR);
    $B2B_TABLE_HEIGHT: var(--B2B_TABLE_HEIGHT);
    $B2B_TABLE_WIDTH_CUSTOME: var(--B2B_TABLE_WIDTH_CUSTOME);
    $B2B_TABLE_PAGINATION: var(--B2B_TABLE_PAGINATION);
    $DYNAMIC_SELECT_BACKGROUND: var(--DYNAMIC_SELECT_BACKGROUND);
    $DYNAMIC_SELECT_TOP1: var(--DYNAMIC_SELECT_TOP1);
    $DYNAMIC_SELECT_WIDTH: var(--DYNAMIC_SELECT_WIDTH); 
    $DYNAMIC_SELECT_PADDING: var(--DYNAMIC_SELECT_PADDING);
    $DYNAMIC_SEARCH_BACKGROUND_COLOR: var(--DYNAMIC_SEARCH_BACKGROUND_COLOR);
    $POLICY_SEARCH_BACKGROUND_COLOR: var(--POLICY_SEARCH_BACKGROUND_COLOR);
    $LOADING_COLOR: var(--LOADING_COLOR);
    $REFRESH_BUTTON_HEIGHT: var(--REFRESH_BUTTON_HEIGHT);
    $WEBHEADER_MARGIN_LEFT: var(--WEBHEADER_MARGIN_LEFT);
    $DATE_PICKER_TOP: var(--DATE_PICKER_TOP);
    $ADD_BUTTON_TOP: var(--ADD_BUTTON_TOP);
    $ADD_BUTTON_MARGIN_LEFT: var(--ADD_BUTTON_MARGIN_LEFT);
    $ADD_BUTTON_BACKGROUND_COLOR: var(--ADD_BUTTON_BACKGROUND_COLOR);
    $FILTER_BUTTON_TOP: var(--FILTER_BUTTON_TOP);
    $FILTER_BUTTON_RIGHT: var(--FILTER_BUTTON_RIGHT);
    $EDIT_BUTTON_MARGIN_LEFT: var(--EDIT_BUTTON_MARGIN_LEFT);
    $FILTER_DYNAMIC_BUTTON_TOP: var(--FILTER_DYNAMIC_BUTTON_TOP);
    $APPLY_BUTTON_HEADER_COLOR: var(--APPLY_BUTTON_HEADER_COLOR);
    $RESET_BUTTON_HEADER_COLOR: var(--RESET_BUTTON_HEADER_COLOR);
    $APPLY_BUTTON_BORDER: var(--APPLY_BUTTON_BORDER);
    $CLEAR_BUTTON_BORDER: var(--CLEAR_BUTTON_BORDER);
    $BUTTON_TEXT_COLOR: var(--BUTTON_TEXT_COLOR);
    $SHOWING_BUTTON_TEXT_COLOR: var(--SHOWING_BUTTON_TEXT_COLOR);
    $SHOWING_BUTTON_HEADER_COLOR: var(--SHOWING_BUTTON_HEADER_COLOR);
    $TREND_DROPDOWN_ACTIVE: var(--TREND_DROPDOWN_ACTIVE);
    $DYNAMIC_TABLE_HEADER_FONT_FAMILY: var(--DYNAMIC_TABLE_HEADER_FONT_FAMILY);
    $DYNAMIC_TABLE_BODY_FONT_FAMILY: var(--DYNAMIC_TABLE_BODY_FONT_FAMILY);
    $QUICKSIGHT_COMPONENT_MARGIN: var(--QUICKSIGHT_COMPONENT_MARGIN);
    $QUICKSIGHT_COMPONENT_WIDTH: var(--QUICKSIGHT_COMPONENT_WIDTH); 
    $QUICKSIGHT_COMPONENT_MIN_HEIGHT: var(--QUICKSIGHT_COMPONENT_MIN_HEIGHT); 
    $SIDE_COLLAPSE_PANEL_BACKGROUND: var(--SIDE_COLLAPSE_PANEL_BACKGROUND);
    $SELECT_BORDER: var(--SELECT_BORDER);
    $PAGINATION_TEXT_COLOR: var(--PAGINATION_TEXT_COLOR);
    $FORM_SELECT_COLOR: var(--FORM_SELECT_COLOR);
    $FORM_SELECT_TEXT: var(--FORM_SELECT_TEXT);
    $SEARCH_ICON: var(--SEARCH_ICON);
    $TRIP_RANGE_PICKER: var(--TRIP_RANGE_PICKER);
    $B2B_DRAWER_CARD_BACKGROUND: var(--B2B_DRAWER_CARD_BACKGROUND);
    $SEARCH_PLACEHOLDER: var(--SEARCH_PLACEHOLDER);
    $LOADING_TEXT: var(--LOADING_TEXT);
    $ALERT_DETAIL_TEXT: var(--ALERT_DETAIL_TEXT);
    $SELECT_FILTER_AFTER_SELECTION_BORDER: var(--SELECT_FILTER_AFTER_SELECTION_BORDER);
    $B2B_SELECT_SEARCH_COLOR: var(--B2B_SELECT_SEARCH_COLOR);
    $DYNAMIC_DRAWER_BACKGROUND: var(--DYNAMIC_DRAWER_BACKGROUND);
    $DYNAMIC_FORM_SELECT_BACKGROUND: var(--DYNAMIC_FORM_SELECT_BACKGROUND);
    $THEME_TYPE: var(--THEME_TYPE);
    $B2B_SEARCH_BACKGROUND: var(--B2B_SEARCH_BACKGROUND);
    $RADIO_BUTTON_BORDER: var(--RADIO_BUTTON_BORDER);
    $B2B_BUTTON_HEIGHT: var(--B2B_BUTTON_HEIGHT);
    $B2B_ARROW_BUTTON_HEIGHT: var(--B2B_ARROW_BUTTON_HEIGHT);
    $COLLAPSE_LIST_BOTTOM_BORDER: var(--COLLAPSE_LIST_BOTTOM_BORDER);
    $DRAWER_HEADER_FONT_WEIGHT: var(--DRAWER_HEADER_FONT_WEIGHT);
    $TRIP_DETAILS_BUTTON_HEIGHT: var(--TRIP_DETAILS_BUTTON_HEIGHT);
    $B2B_CONTENT_MARGIN_LEFT: var(--B2B_CONTENT_MARGIN_LEFT);
    $TRIP_INSIGHTS_DRAWER_WIDTH: var(--TRIP_INSIGHTS_DRAWER_WIDTH);
    $ALERT_PAGINATION_ACTIVE: var(--ALERT_PAGINATION_ACTIVE);
    $ALERT_PAGINATION_INACTIVE: var(--ALERT_PAGINATION_INACTIVE);
    // $INPUT_MEDIA_QUERY_TOP: var(--INPUT_MEDIA_QUERY_TOP);
    // $INPUT_MEDIA_QUERY_RIGHT: var(--INPUT_MEDIA_QUERY_RIGHT);