@import "../../global.scss";

.login-form-forgot {
    display: flex;
    justify-content: center;
}
.ant-col-rtl .login-form-forgot {
    float: left;
}
.login-form-button {
    width: 100%;
    background: $MIS_ANALYSER_BUTTON;
    border-radius: 4px;
    font-weight: bold;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
    color: #fff;
    background: $MIS_ANALYSER_BUTTON;
    border-color: #40a9ff;
}

.connectm-forgot {
    position: absolute;
    // box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0px 4px 4px 0px;
    z-index: 15;
    min-width: 700px;
    max-width: 700px;
    min-height: 368px;
    max-height: 368px;
    top: 50%;
    left: 50%;
    margin: -168px 0 0 -350px;
    height: 1px;

    .forgot-password-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        // height: 300px;
        // background: #ffffff;

        .login-image {
            background: $MIS_ANALYSER_BUTTON;
            mix-blend-mode: normal;
            box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
            border-radius: 4px 0px 0px 4px;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .logo-title {
                margin-left: 32px;
            }
            .motovolt {
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
                line-height: 36px;
            }
            .command-center {
                font-style: normal;
                font-weight: normal;
                font-size: 19px;
                line-height: 24px;
            }
        }

        .login-form {
            background: #ffffff;
            // max-width: 300px;
            .form-body {
                margin-left: 24px;
                margin-right: 24px;
                margin-top: 24px;

                .ant-input {
                    background: #ececec;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    @media screen and (min-width: 1450px) {
                        height: 64px;
                        width: 100%;
                        font-size: 21px;
                    }
                }

                .ant-form-item-control-input {
                    margin: 0 auto;
                    width: 100%;
                    @media screen and (min-width: 1450px) {
                        width: 365px;
                    }
                }

                .login-form-button {
                    width: 100%;
                    background: $MIS_ANALYSER_BUTTON;
                    border-radius: 4px;
                    font-weight: bold;
                    @media screen and (min-width: 1450px) {
                        font-size: 24px;
                        height: 64px;
                    }
                }
            }
        }
    }
    .logo {
        margin: 16px auto;
        text-align: center;
        .motovolt-logo-icon {
            height: 60px;
            width: 80px;
            @media screen and (min-width: 1450px) {
                height: 95px;
                width: 101px;
            }
        }
        @media screen and (min-width: 1450px) {
            margin: 40px !important;
        }
    }
    .forgot-footer {
        font-size: 12px;
        display: flex;
        justify-content: space-between;
    }
    .notification-toast.error {
        background: #c14d69;
    }

    .notification-toast.success {
        background: #2bb4bd;
    }
    .notification-toast {
        // padding: 16px;
        text-align: center;
        height: 48px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        // padding: 10px 10px 0px 10px;

        // margin-bottom: 16px;
    }
    .ant-form-item-explain {
        color: white;
    }
    .forgot-password-label {
        color: #2c3987;
        text-align: center;
        height: 44px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1450px) {
    .connectm-forgot {
        min-width: 984px !important;
        max-width: 984px !important;
        min-height: 564px !important;
        max-height: 564px !important;
        margin: -282px 0 0 -492px !important;
        .forgot-password-container {
            height: 100%;
        }
    }
    .notification-toast {
        height: 60px !important;
        // margin-bottom: 16px;
    }
    .forgot-password-label {
        height: 60px;
        margin: 0 auto;
        width: 365px;
    }
    // .login-form-forgot {
    //     font-size: 18px;
    // }
}